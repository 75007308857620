import React, { useState } from "react";
import { Sun, Moon } from "react-feather";

// eslint-disable-next-line react/prop-types
const LightDarkSwitch = ({ theme, updateTheme }) => {
    const [dark, setDark] = useState(false);
    const onClickHandler = () => {
        setDark(!dark);
        theme === "light" ? updateTheme("dark") : updateTheme("light");
    };

    return (
        <button
            type="button"
            className="light-dark-switch"
            onClick={onClickHandler}
        >
            <div>{dark ? <Moon /> : <Sun />}</div>
        </button>
    );
};

export default LightDarkSwitch;
